.copyright-container {
  background-color: #000000;

  .copyright-logo {
    margin: auto;
    height: auto;
    width: 200px;
  }

  .social-media-section {
    z-index: 1;

    a {
      margin-left: 10px;
      margin-right: 10px;

      .social-media-icon {
        color: white;
        width: 30px;
      }
    }
  }
}