.ntf-collection-section {
  .img-thumbnail {
    background-color: white;
    width: 300px;
    height: 300px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    border: 2px white solid;
    border-radius: 10px;
  }

  .token-name {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
  }

  .token-supply {
    color: #9d1f21;
    font-size: 18px;
    font-weight: 600;
  }

  .btn-mint {
    background-color: #9d1f21;
    color: white;
  }

  .wallet-connect-button {
    background-color: #9d1f21;
    color: white;
  }
}