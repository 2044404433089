.release-detail-section{
  color: #000;
  #release-view-wrapper{
    color: #000;
  }
  h1{
    color: #FFFFFF;
  }

  .size-item{
    cursor: pointer;
    padding: 1px;
    margin: 1px;
    border: 1px solid #9d1f21;
    text-align: center;
    // vertical-align: middle;
    /* line-height: 25px; */
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #222;
    display: block;
    background-color: #FFF;
    border-radius: 5px;
    position: relative;
  }
  
  .selected>.size-item, .size-item:hover{
    background-color: #9d1f21; 
    color: #FFF;
  }
  
  // .size-item.selected{
  //   /* border-color: #000;  */
  //   background-color: #9d1f21;
  //   color: #9d1f21;
  // }
  
  .release-sizes .nav-tabs{
    border-bottom: 1px solid #9d1f21;
    margin-bottom: 8px;
  }
  .release-sizes .nav-tabs .nav-item{
    margin-right: 4px;
    
  }
  .release-sizes .nav-tabs > li > .nav-link{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #9d1f21;
    // border-bottom: 0px;
    font-weight: 300;
    margin-right: -1px;
    min-width: 110px;
  }

  .release-sizes .nav-tabs > li{
      margin-bottom:0px;
  
  }
  .release-sizes .tab-content{
    background-color: transparent;
  }

  .release-sizes .nav-tabs > li > .nav-link.active, 
  .release-sizes .nav-tabs > li > .nav-link:hover{
    background-color: #9d1f21;
    color: #FFF;
  }

  .release-sizes .nav-tabs > li > .nav-link.active:hover{
    background-color: #313131;
    color: #FFF;
    border-color: #151515;

  }
  .release-sizes .nav-tabs > li > .nav-link{
    background-color: #fff;
    color: #000;
  }

  .release-sizes{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #buy-now-wrapper{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .image-gallery-thumbnail{
        border: 2px solid transparent;
  }
  .image-gallery-thumbnail.active{
        border: 2px solid #000;
  }
  
  .help-block{
    color: #222;
  }
  
  #estimate_delivery{
    display: block;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 8x;
  }
  .image-gallery-bullets .image-gallery-bullet:hover{
    border: 1px solid #9d1f21;
    background-color:transparent;
    /* margin: 5px; */
    box-shadow: 0px 0px 0px #999
  }
  .image-gallery-bullets .image-gallery-bullet.active:hover{
    box-shadow: 0px 0px 0px #999;
    background-color: #9d1f21;
  }
  .image-gallery-bullets .image-gallery-bullet.active{
    background-color: #9d1f21;
    border: 1px solid #FFF;
  }
  
  .image-gallery-bullets .image-gallery-bullet{
    border: 1px solid #9d1f21;
    background-color: #000;
    /* margin: 5px; */
    box-shadow: 0px 0px 0px #999
  }
  .image-gallery-bullets{
    /* height: 100%;
    width: 10px;
    top: 40%;
    left: auto;
    right: -10px; */
    bottom: -20px
  }
  .image-gallery-slide{
    background: transparent;
    /* height: 300px; */
  }
  .image-gallery-left-nav, .image-gallery-right-nav{
    /* top:0px; */
    height: 100%;
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
    z-index: 1;
  }
  .image-gallery-icon:hover{
    color: #9d1f21;
  }
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
    height: 60px;
    width: 30px;
  }

  .image-gallery-fullscreen-button{
    z-index: 1;
  }
  
  .image-gallery-fullscreen-button::before, .image-gallery-play-button::before {
      font-size: 2em;
      padding: 8px;
      text-shadow: 0 0px 0px #999;
  }
  
  .image-gallery-left-nav, .image-gallery-right-nav{
    font-size: 3em;
  }
  .image-gallery-fullscreen-button::before, .image-gallery-play-button::before, .image-gallery-left-nav::before, .image-gallery-right-nav::before{
        text-shadow: 0 0px 0px #999;
  }
  .image-gallery-fullscreen-button:hover::before, .image-gallery-play-button:hover::before, .image-gallery-left-nav:hover::before, .image-gallery-right-nav:hover::before{
    color: #000;
  }
  .image-gallery-content.fullscreen{
    background: transparent;
  }
  .image-gallery-content.fullscreen .image-gallery-bullets .image-gallery-bullet{
    background-color: #FFF;
    border: 1px solid #FFF;
  }
  
  .image-gallery-content.fullscreen .image-gallery-bullets .image-gallery-bullet.active {
    background-color: #9d1f21;
    border: 1px solid #FFF;
  }
  .image-gallery-fullscreen-button::before,
  .image-gallery-play-button::before,
  .image-gallery-left-nav::before,
  .image-gallery-right-nav::before {
    color: #000;
  }
  #authentic-badge{
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }
  .image-gallery{
    margin-bottom: 35px;
  }
  
  #release-view-wrapper .page-header{
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  #release-view-wrapper .description{
    font-size: 13px;
  } 
  .image-gallery-content .image-gallery-slide .image-gallery-image{
    border-radius: 10px;
  }

  .near-retail{
    font-weight: 400;
    color: #000;
    text-align: left;
    padding: 1px;
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 0px;
    // z-index: 1;
    font-size: 10px;
  }

  @media (max-width: 768px) {
    .image-gallery-left-nav, .image-gallery-right-nav{
      display: none;
    }

    .release-item{
      padding-left: 4px;
      padding-right: 4px;
    }
    .checkout-field-select{
      padding-bottom: 5px;
    }
    #firstname-field-wrapper.checkout-field.col-xs-12{
      width: 50%;
    }
  }  
}
.release-collection-section {
  .release-item-image {
    padding-bottom: 30px;
    padding-top: 43px;
    position: relative;
    cursor: pointer;

    /* border-bottom: 1px solid #ccc; */
  }
  .release-item-image a{
    display: block;
  }
 
  .release-style-code {
    min-height: 18px;
    font-size: 12px;
    color: #666;
  }
  .release-item-txt {
    height: 90px;
    overflow: hidden;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    padding: 5px;
  }
  .release-item-txt a,
  .release-item-txt a:hover,
  .release-item-txt a:focus {
    text-decoration: none;
  }
  .release-price-from, .release-price-dicount {
    font-weight: 400;
    color: #000;
    text-align: right;
    padding: 5px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .release-price-dicount{
    right: unset;
    left: unset;
    top: unset;
    color: #f0332a;
    width: 100%;
    font-size: 90%;
    text-align: center;
  }

  .near-retail{
    font-weight: 400;
    color: #000;
    text-align: left;
    padding: 5px;
    display: inline-block;
    position: absolute;
    left: 5px;
    top: 0px;
    // z-index: 1;
    font-size: 12px;
  }
}
.tooltip-inner {
  max-width: none; 
}
.pac-container{
  z-index: 99999999;
  // top: 0px !important;
}

#checkout-wrapper{
  @media (max-width: 768px) {
    .checkout-field-select{
      padding-bottom: 5px;
    }
    #firstname-field-wrapper.col-12,
    #lastname-field-wrapper.col-12,
    #city-field-wrapper.col-12,
    #postal-field-wrapper.col-12{
      width: 50%;
      flex: 0 0 auto;
    }
  }  
}