@import "variables";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "bootswatch";
@import "../node_modules/animate.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";

body {
  background-color: #000000;
  // padding-top: 70px;
  // display: flex;
  // align-items: flex-start;  
}

.btn-bears{
  background-color: #9d1f21;
  color: white;
}

.btn-bears:hover, .btn-bears.btn-bears-invert{
  background-color: #313131;
}

.btn.btn-bears-dark{
  align-items: flex-start;
  text-decoration: none !important;
  float: none;
  margin: 0px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  background: 0 0;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  background-color: #9d1f21;
  color: white;
  outline: 0;
  border: 1px solid #111;
  padding: 5px 24px;
  border-radius: 5px !important;
  text-transform: capitalize;
  width: 100%;
}

.btn.btn-bears-dark:hover,
.btn.btn-bears-dark:active, 
.btn.btn-bears-dark:focus{
  background-color: rgba(255,255,255,.75);
  color: #111;
  border-color: #ccc;
  text-decoration: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

h1.page-title{
  margin-top: 0px;
  font-size: 24px;
  font-weight: 500;
}

.clear-paddings{
  padding-left: 0 !important;
  padding-right: 0  !important;
}
.clear-padding-left{
  padding-left: 0  !important;
}
.clear-padding-right{
  padding-right: 0  !important;
}

.clear-margins{
  margin-left: 0 !important;
  margin-right: 0  !important;
}
.clear-margin-left{
  margin-left: 0  !important;
}
.clear-margin-right{
  margin-right: 0  !important;
}

.btn-snkrly{
  display: block !important;
  box-shadow: none  !important;
  border-radius: 0px  !important;
  background: #000  !important;
  border: 1px solid #000 !important;
  color: #FFF !important;
  padding: 8px  !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-size: 15px !important;
}
.btn-snkrly:hover{
  background: #FFF !important;
  color: #000 !important;
  border-color:  #000 !important;
}
.btn-invite{
  /* display: block !important; */
  /* box-shadow: none  !important; */
  border-radius: 0px  !important;
  /* background: #FFF  !important; */
  background: rgba(255, 255, 255, 0.8) !important;
  border: 0px solid #000 !important;
  color: #000 !important;
  padding: 10px  !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  font-size: 16px !important;
  font-weight: 400;
}
.btn-invite:hover{
  /* background: #000 !important; */
  background: rgba(0, 0, 0, 0.6) !important;
  color: #FFF !important;
  border-color:  #FFF !important;
  border: 0;
}
.form-item{
  margin-bottom: 8px;
}

.btn-remove{
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  font-size: 15px;
}
.addressItemWrapper{
  font-size: 13px;
  padding: 3px;
}

#shopping-cart .table > thead > tr > th, 
#shopping-cart .table > tbody > tr > th, 
#shopping-cart .table > tfoot > tr > th, 
#shopping-cart .table > thead > tr > td, 
#shopping-cart .table > tbody > tr > td, 
#shopping-cart .table > tfoot > tr > td{
  border-top: 1px solid #222;
}
#shopping-cart .table > tbody > tr > td:last-child{
  text-align: right;
}
#shopping-cart .table > thead > tr > th{
  border-bottom: 1px solid #222;
}
#shopping-cart .table{
    margin-bottom: 8px;
}

#checkout-form.form-horizontal .form-group{
    margin-right: 0;
    margin-left: 0;
    padding: 0;
}

.out-of-stock{
  border: 1px solid #000;
  padding: 8px;
  display: inline-block;
  border-radius: 0;
  color: #000;
  text-transform: uppercase;
}
/* #shopping-cart .cart-image{
  max-width: 100px;
  min-width: 50px;
  width: 50px;
} */
.social-items-wrapper{
  text-align: right;
  float:right;
  margin-left: 30px;
}
.social-item{
  display: inline-block;
}
@media (min-width: 768px){
  #footer .navbar-nav {
      float: right;
  }
}
@media (max-width: 768px) {
  .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
    padding: 2px;
  }
  .order-item{
    padding-left: 5px;
    padding-right: 5px;
  }
  .image-gallery-fullscreen-button{
    display: none;
  }

  footer>div {
    padding: 5px 5px;
    bottom: 0;
    text-align: center;
  }
  .social-items-wrapper{
    text-align: center;
    float: none;
    margin-left: 0px;
  }
  .flex-sm-row {
    flex-direction: unset;
  }  
  .navbar>.container, .navbar>.container-fluid, 
  .navbar>.container-lg, .navbar>.container-md, 
  .navbar>.container-sm, .navbar>.container-xl, 
  .navbar>.container-xxl{
    max-width:unset !important;
  }
  .navbar>.container, .navbar>.container-sm {
    max-width: unset !important;
  }
  .flex-sm-row{
    flex-direction: column !important;
    
    -webkit-flex-direction: column !important;
  }  
}
@media (min-width: 576px){

}

.form-control:focus {
    border-color: #000;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 0, 0, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 0, 0, .6);
    box-shadow:none;
    -webkit-box-shadow:none;
}

input[type="text"] {                       
  font-size: inherit;
}
 
.social-item-wrapper{
  display: inline;
  margin-left: 3px;
}

.release-price-from{
  font-weight: 400;
  color: #000;
  text-align: right;
  padding: 5px;
  position: absolute;
  right: 0px;
  top:0px;
}
/*
.buy-from-price{
  color: #000;
}*/

.custom-tooltip-wrapper{
  background-color: #FFF;
  color: #262626;
  border: 1px solid #bbb;
}

#dashboard-report-range{
  cursor: pointer;
  font-weight: normal;
  vertical-align: top;
  margin-bottom: 8px;
  margin-top: 8px;
}
#dashboard-report-range .input-group-addon:last-child{
  border: 0;
}
#dashboard-report-range .input-group .form-control,
#dashboard-report-range .input-group .form-control:focus{
  border-color: #eee !important;
  box-shadow:none
}
.dash-wall{
  /* border: 1px solid #000; */
  /* border-radius: 0px; */
  /* padding: 10px 10px 10px 10px; */
  /* margin-bottom: 15px; */
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  background: none;
}

.dash-text{
  font-size: 20px;
  color: #666;
  display: inline-block;
  white-space: nowrap;
  font-weight: 600;
}

.dash-label{
  text-transform: uppercase;
}

.dash-icon{
  float: right;
  position: absolute;
  right: 8px;
  top: 8px;
}

.tootltip-wrapper{
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  list-style-type: none;
  margin: 0px;
}
.tootltip-label{
  font-weight: bold;
}

.clearfix {
  display: block;
  clear: both;
  content: "";
}

.form-control-static .thumbnail{
  margin-bottom: 0px;
}
.snkrly-icon{
  margin-bottom: 20px;
}
.snkrly-icons{
  margin-bottom: 20px;
}
.snkrly-icon-info{
  font-size: 20px;
}
.nowrap{
  white-space: nowrap;
}

.transparent-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  background-color: #000;
  opacity: 0.5;
  z-index: -1;
  margin-left: -20px;
}
#become-influencer{
  margin-top: 65px;
}

#pagination-wrapper{
  text-align: center;
}


#search-icon{
    display: none;
}
@media (min-width: 768px){
  .navbar-default .navbar-form.navbar-left {
      width: 30%;
  }
  /* .navbar-header{
    width: 40%;
  } */
  .navbar-header .navbar-form .form-control{
    width: 80%;
  }
  .navbar-form .form-group{
    display: block;
  }
  .navbar-form .form-control{
    width: 95%;
  }
  .navbar-header .navbar-form .form-group{
    display: block;
  }
  .navbar-default .navbar-header .navbar-form.navbar-left{
    width: 70%;
  }
}
@media (max-width: 768px) {
  .navbar-default .navbar-form{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 3px;
  }
  .navbar-default .navbar-left{
    float: none;
    width: 100%;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 1px;
    margin-top: 9px;
    padding: 0px;
  }

  .navbar-header{
    text-align: center;
  }

}
.search-menu-item{
  width: 100%
}
/* .navbar-right.closed .search-menu-item{
  width: 0px;
} */
.navbar-right.closed{
  float: right
}
.navbar-right.closed #search{
  display: none;
}

.navbar-right.open #search{
  display: block;
}
.navbar-right.open{
  float: none
}

.navbar-right.open .search-menu-item{
  width: 100%;
}
.pagination > li > a, .pagination > li > span{
  border: 0px;
  border-bottom: 1px solid #000;
}

.order-image{
  max-width: 100px;
  // height: 80px;
  background-position: center center;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color:none;
  -webkit-box-shadow:none;
  box-shadow:none;
  transition: background-color 5000s ease-in-out 0s;
}
@media (min-width: 768px){
  #page-profile .form-horizontal .control-label {
      padding-top: 10px;
      margin-bottom: 0;
      text-align: left;
  }
}
#page-profile .form-horizontal .form-control {
  padding: 5px 5px;
}

.main-page .static-page{
  padding-top: 5em;
}

#change-word{
  font-weight: 550;
  /* font-style: oblique; */
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  /*border-right: .15em solid orange;*/ /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
 /* margin: 0 auto;*/ /* Gives that scrolling effect as the typing happens */
  /*letter-spacing: 0.05em;*/ /* Adjust as needed */
  border-bottom: 1px double #FFF;
  /*animation: 
    typing 3.5s steps(40, end);*/
    /* blink-caret .75s step-end infinite; */
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
/* @keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
} */
.Select-control{
  color: #000  !important;
  border: 0 !important;
  border:0px   !important;
  border-bottom: 1px solid #000  !important;
  border-radius: 0px  !important;
}
.Select-arrow{
      border-color: #000 transparent transparent !important;
}
.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #000 !important;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  /* border: 1px solid #000; */
  border-bottom: 1px solid #000;
}

.Select-menu-outer {
  border:1px solid #000  !important;
  border-radius: 0px;
}
.Select-option:last-child {
  border-bottom-right-radius: 0px  !important;
  border-bottom-left-radius: 0px  !important;
  /* border-bottom-color: #000; */
  border-bottom:0px;
}
.Select-option.is-selected {
  background-color: #000  !important;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 0, 0, 0);
  color: #fff  !important;
}
.Select-option.is-focused {
  background-color: #000  !important;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 0, 0, 0.08);
  color: #fff !important ;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #000  !important;
  box-shadow: none  !important;
  background: #fff  !important;
}
@media (min-width: 768px){
  #intro.main.style1 .box header{
    padding: 30px;
  }
}
#snkrly-wrapper:not(.main-page) .static-page.container{
  padding-left: 0px;
  padding-right: 0px;
}
.navbar{
  border-radius: 0px;
}

#become-influencer .input-group-addon{
  font-size: 20px;
}

.ql-editor{
  min-height: 100px !important;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}
.navbar-brand.navbar-brand{
  display: table-cell;
  vertical-align: middle;
}
.navbar-brand:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.navbar-brand>img{
  display: inline-block;
  max-height: 45px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.5rem;
  margin: 0.3rem 0rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #55595c;
  background-color: transparent; 
  background-clip:border-box;
  border: 0 solid #ced4da;
  border-bottom: 1px solid #ced4da;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s 
}
.form-control:focus{
  background-color: transparent; 
}
.form-control.is-valid, .was-validated .form-control:valid,
.was-validated .form-control:invalid, .form-control.is-invalid{
  background-image: none;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .form-control:valid:focus, .form-control.is-valid:focus{
  box-shadow:none;
  // border-color:unset; 
}
.mainpage-wrapper-container {
  background: linear-gradient(0deg, #ff9900 0%, #febe39 39%, #e29f16 86%);
  position: relative;
  height: 100%;

  h1 {
    color: black;
    font-size: 42px;
    font-weight: 700;
  }
  .release-item-wrapper, .order-item-wrapper {
    position: relative;
    margin-bottom: calc(var(--bs-gutter-x) * .5);
    margin-top: calc(var(--bs-gutter-x) * .5);
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px white solid;
  }
  .continer{
    overflow-x: hidden !important;
  }

  .pagination > .active > a, 
  .pagination > .active > span, 
  .pagination > .active > a:hover, 
  .pagination > .active > span:hover, 
  .pagination > .active > a:focus, 
  .pagination > .active > span:focus{
      background-color: #9d1f21;
      border-color: #9d1f21;
      color: #FFF;
  }
  .pagination > li > a:hover, 
  .pagination > li > span:hover, 
  .pagination > li > a:focus, 
  .pagination > li > span:focus{
      background-color: #9d1f21;
      color: #FFF;
      border-color: #9d1f21;
  }
  .pagination > li > a, 
  .pagination > li > span{
    color: #000;
    border-color: #9d1f21;
    border-radius: 3px;
    margin: 3px;
    min-width: 55px;
  }
  .pagination > .disabled > span, 
  .pagination > .disabled > span:hover, 
  .pagination > .disabled > span:focus, 
  .pagination > .disabled > a, 
  .pagination > .disabled > a:hover, 
  .pagination > .disabled > a:focus{
        border-color: #9d1f21;
  }  

  @media (max-width: 768px) {
    .pagination > li > a, 
    .pagination > li > span{
      // --bs-pagination-padding-x: 0.75rem;
      // --bs-pagination-padding-y: 0.375rem;      
      min-width: 33px;
      padding-left: var(--bs-pagination-padding-y);
      padding-right: var(--bs-pagination-padding-y);
      font-size: 0.75rem;
      // padding: 0.2rem;

    }
  }
}
