@import "../../../variables";

.input-number {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: transparent;
  overflow: hidden;
  border-radius: 20px;
  margin: 2px;

  span {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    display: inline-block;
    background-color: $orange;
    height: 100%;
    width: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: $white;
    letter-spacing: -1px;
  }

  button {
    display: inline-block;
    width: 50px;
    height: 100%;
    border: none;
    color: $white;
    font-size: 20px;
    cursor: pointer;
    background-color: $gray-500;
    transition: background-color .2s ease;

    :hover {
      background-color: $secondary;
    }
  }

  button:nth-of-type(1) {
    padding-right: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  button:nth-of-type(2) {
    padding-left: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}