.site-logo {
  width: 200px;
  cursor: pointer;
}

.navbar {
  background-color: rgba(26, 26, 26, 0.7) !important;
  padding-top: 15px  !important;
  padding-bottom: 15px !important;
  // position: fixed !important;
  // top:0;
  // z-index: 999;
  // width: 100%;   
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form{
  border: none;
}

#navbar .nav-item{
  white-space: nowrap;
  min-width: 170px;
  margin-right: .2em;
  margin-left: .2em;
  margin-bottom: 1px;
}
.connect-button {
  background-color: #9d1f21;
  color: white;
}

.disconnect-button {
  background-color: #313131;
  color: white;
}
.nav-item a.btn-block{
  display: block;
}
.nav-item a.btn-block.btn-bears.active {
  background-color: #313131;
  color: #FFF;
}
@media (min-width: 768px){
  .navbar-right {
    float: right!important;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 320px) {
  .site-logo {
    width: 120px;
  }
  .connect-button {
    // margin-right: 15px;
    font-size: 12px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .site-logo {
    width: 200px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {

}