#filter-wrapper {
  min-height: 500px;
  /* max-width: 255px; */
}

#release-header {
  margin-bottom: 10px;
}

#release-header .show-filter {
  cursor: pointer;
  display: inline-block;
  height: 51px;
  line-height: 51px;
}

#release-header .show-filter .icon {
  font-size: small;
  margin-left: 5px;
}

#release-header #search.form-control{
  background-color: #FFF;
  border: 0px;
  border-radius: 4px;
  margin: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

#row-container {
  overflow-x: hidden;
  display: flex;
  justify-content: flex-end; 
  align-content: stretch;
  justify-self:right;
}

#column-filter {
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  float: left !important;
  width: 30%;
  margin-left: -100%;
  padding-right: 0px;
  padding-left: 5px;
  z-index: -1;
  -webkit-transition: margin-left .3s ease-in-out;
  -o-transition: margin-left .3s ease-in-out;
  transition: margin-left .3s ease-in-out;
  
}

#release-filter {
  width: 100%;
  float: right !important;
  align-items: flex-end;
  justify-content: flex-end;
  align-content:stretch ;
  -webkit-transition: width .3s ease-in-out;
  -o-transition: width .3s ease-in-out;
  transition: width .3s ease-in-out;
  // background-color: #fff;
}

.open-filters #column-filter {
  z-index: 0;
  margin-left: '0%';
}
.open-filters #release-filter, .open-filters #release-loader{
  width: 70% !important;
}

#seachKeyword {
  text-align: center;
  font-size: 20px;
}

#seachKeyword .title {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

#seachKeyword .info {
  font-size: 14px;
  
  text-align: center;
}

#column-filter .filter-header {
  display: none;
}

.d-grid.btn-filter-wrapper-float {
  display: none !important;
  position: -webkit-sticky;
  position: sticky;
  bottom: 30px;
  text-align: center;
}

#test-sticky {
  position: sticky;
  background-color: red;
  width: 100%;
  height: 50px;
  z-index: 100000;
  top: 0;
}

#release-header .Select-option {
  padding: 3px 5px;
}

.medium-size {
  display: none;
}
.medium-size .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20000;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 0;
}

.medium-size .overlay.open {
  opacity: 0.6;
  display: flex;
  visibility: visible;
}

#sortByContainer .control-label {
  text-align: right;
  white-space: nowrap;
  margin: 0px !important;
  padding-top: 10px;
}

#filter-wrapper .filter-group-button.row {
  margin-left: 0px;
  margin-right: 0px;
}
#filter-wrapper .filter-group-button button {
  width: 100%;
}

#filter-wrapper .filter-group-button [class*=col-] {
  padding-left: 2px;
  padding-right: 2px;
  padding: 2px;
}

#filter-wrapper  .filter-group-button.size .btn-size-wrapper {
  display: inline-block;
  width: 46px;
  height: 46px;
  margin-left: 2px;
  margin-top: 2px;
}

#filter-wrapper  .filter-group-button.size .btn-size-wrapper button {
  padding-left: initial !important;
  padding-right: initial !important;
  height: 100%;
}

#filter-wrapper .filter-group-button button.btn-snkrly {
  background-color: #fff !important;
  color: black !important;
  font-weight: initial;
  font-size: 13px !important;
}

#filter-wrapper .filter-group-button button.btn-snkrly:hover {
  /* text-decoration: underline; */
  background-color: #000 !important;
  color: white !important;
}

#filter-wrapper .filter-group-button button.btn-snkrly.active {
  background-color: #000 !important;
  color: white !important;
}

#filter-wrapper .filter-item .title {
  margin: 5px;
}

#filter-contianer .filter-block{
  margin-bottom: 15px;
}
#filter-contianer .filter-block:after,
#filter-contianer .filter-block:before{
  clear: both;
  display: table;
  content: " ";
}
#filter-contianer .filter-block .filter-title{
  font-weight: bold;
  margin: 5px;
}
#filter-contianer .btn.btn-snkrly{
  padding: 5px!important;
}
#shipping-filter .btn-snkrly{
  text-transform: uppercase;
}

#size-filter .btn.btn-snkrly{
  border-radius: 0px !important;
}
#filter-wrapper .col-xs-6, #shipping-filter .col-xs-6{
  width: 33.33%;
}


.btn.btn-snkrly-dark{
  align-items: flex-start;
  text-decoration: none !important;
  float: none;
  margin: 0px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  background: 0 0;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  background-color: #111;
  color: #FFF;
  outline: 0;
  border: 1px solid #111;
  padding: 5px 24px;
  border-radius: 5px !important;
  text-transform: capitalize;
}

.btn.btn-snkrly-dark:hover,
.btn.btn-snkrly-dark:active, 
.btn.btn-snkrly-dark:focus{
  background-color: rgba(255,255,255,.75);
  color: #111;
  border-color: #ccc;
  text-decoration: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}
#search-continer{
  position: relative;
  padding: 0px;
}

#search-continer.open-suggest #tranding-data{
  display: block;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;  
  top: 37.5px;
  z-index: 2;
  border: 1px #ccc;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  // right: calc(var(--bs-gutter-x) * .5);
  // left: calc(var(--bs-gutter-x) * .5);
}

#search-continer.open-suggest #search{
  display: block;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;  
}

#tranding-data{
  position: absolute;
  width:100%;
  display: none;
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.suggest-item, .no-result{
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
}
.suggest-item:hover, .current-suggest{
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.react-select__control{
  border: none !important;
  box-shadow: none !important;
}
.react-select__menu{
  top:37px  !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: 0px !important;

}
.react-select__control--is-focused{
  border: none !important;
  box-shadow: none !important;
}
.react-select__control--menu-is-open{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.react-select__option{
  white-space: nowrap;
}
.react-select__option--is-selected{
  background-color:#000 !important;
  border-bottom-right-radius: 0 !important;
}
.react-select__menu-list{
  padding: 0px !important;
}
.react-select__option.react-select__option--is-focused{
  background-color:#ccc;
}
@media (hover: none) {
  .filter-group-button button.btn-snkrly:hover {
    /* text-decoration: underline; */
    background-color: inherit !important;
    color: inherit !important;
  }

  .filter-group-button button.btn-snkrly.active {
    background-color: #000 !important;
    color: white !important;
  }
}

@media only screen and (max-width: 1023px) {
  #release-header #showFilterContainer {
    display: none;
  }

  #search-continer{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    // padding-left: 10px;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
  #sortByContainer{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    // padding-left: 10px;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: 0;//calc(var(--bs-gutter-x) * .5);
  }
  #search.form-control{
    line-height: 1.35;
  }
  #column-filter {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100vw;
    margin-left: 0px;
    width: 100%;
    background: white;
    padding: 15px;
    overflow-y: auto;
    margin-right: -100%;
    z-index: 1000 !important;
    -webkit-transition: left .3s ease-in-out;
    -o-transition: left .3s ease-in-out;
    transition: left .3s ease-in-out;
  }

  .d-grid.btn-filter-wrapper-float {
    display: block !important;  
    opacity: 0.9;
    padding-left: 20%;
    padding-right: 20%;
  }

  // #row-container{
  //   justify-self: left;
  // }

  // #row-container, #column-filter, #release-filter {
  //   display: block;
  //   float: none !important;
  //   justify-content:unset;
  //   align-content: unset;
  //   align-items: unset;
  //   justify-items: unset;
  // }

  #row-container.open-filters #release-filter{
    width: 100% !important;
  }

  #row-container.open-filters #column-filter{
    left:0vw;
  }
  
  body.filter-open{
    overflow: hidden;
  }

  #column-filter .filter-header {
    border-bottom: 1px solid rgb(222, 222, 222);
    margin-bottom: 15px;
    padding: 0px;
    text-align: center;
    position: relative;
    padding-top: 0px;
    position: relative;
    display: block;
    /* display: flex;
    align-content: flex-end;
    justify-content: flex-end; */
  }

  #column-filter .filter-header .name,
  #column-filter .filter-header .close-btn-wrapper {
    /* display: table-cell; */
    /* height: 100%; */
    line-height: inherit;
    text-align: center;
    padding: 0px;
    /* vertical-align: middle;
    justify-self:center;
    align-self: center; */
  }

  #column-filter .filter-header .name {
    font-weight: bold;
    font-size: 18px;
    /* border-right: 1px solid rgb(222, 222, 222); */
    text-transform: uppercase;
    width: 100%;
    display: block;
  }

  #column-filter .filter-header .close-btn-wrapper {
    width: auto;
    position: absolute;
    right: 0px;
    top: -12px;
    padding: 0px;
  }

  #column-filter .filter-header .close-btn-wrapper button {
    display: inline-block !important;
  }
  
  #filter-wrapper {
    margin: 0 auto;
  }


  #search-continer.open-suggest #tranding-data{
    right: calc(var(--bs-gutter-x) * .5);
    left: calc(var(--bs-gutter-x) * .5);
    width: auto;
  }  
}

/* @media only screen and (max-width: 1023px) and (min-width: 768px) {
  #column-filter {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 375px;
    z-index: 20001;
    background: white;
    padding: 15px;
  }

  #column-filter.collapsed {
    margin-left: -375px;
  }

  .medium-size {
    display: inline;
  }
} */