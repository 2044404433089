.order-view-section{
  color: #000;
  
  #estimate_delivery{
    display: block;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 8x;
  }
  table.table td, table.table th{
    padding: 8px;
    vertical-align: middle;
  }
  table.table td .card{
    background: transparent;
    padding: 0px;
    border: 0px;
  }

  #order-messages-container{
    margin-bottom: 5px;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // border: 1px solid #000;
    // background-color: #000;
    // box-shadow: 5px 5px 5px #000;
    color: #FFF;
  }
  
  .order-message-item-wrapper{
    margin-top: 0px;
    margin-bottom: 4px;
    margin-right: 15px;
    margin-left: 0px;
    padding: 8px;
  }
  
  .msg-content-left{
    /* float: left; */
    background:none;
    border: 1px solid #eee;
  }
  .msg-content-right{
    /* float: right; */
    border: 1px solid #000;
    background-color: #000;
    /* color: #FFF; */
  }
  .message-date .formated-date{
    /* display: inline-block; */
    float: right;
  }
  
  .msg-author{
    display: inline-block;
    padding-right: 15px;
  }
  
  .message-date{
    white-space: nowrap;
    border-bottom: 1px solid;
  }
  .message-date:after{
    clear: both;
  }
  .message-body{
    clear: both;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #order-send-message-wrapper textarea.form-control{
    border: none;
    color: #000;
    resize: none;
  }
  
}
#shipping-address{
  margin-top: 10px;
  margin-bottom: 10px;
}
.order-list-section{
  .order-item-wrapper{
    padding-left: 10px;
    padding-right: 10px;
  }


  @media (max-width: 768px) {
    .order-item{
      padding-left: 4px;
      padding-right: 4px;
    }
  }  
}